<template>
    <CustomDialog ref="dialog" title="Convertir Archivo KMZ" @accept="close" @cancel="close">
        <div class="py-2">
            <v-label>
                La conversión de un archivo KMZ es un proceso que usualmente se da de forma automática al subir un archivo.
                Puede darse algunos casos en donde este proceso no se haya realizado correctamente, y es en esta instancia donde usted puede realizar el proceso de forma manual.
            </v-label>
        </div>
        <v-row no-gutters class="ma-0">
            <v-col cols="12">
                <span class="title-1">¿Qué hace este proceso?</span>
            </v-col>
            <v-col cols="12" md="6">
                <v-label>
                    Convierte los datos de un archivo KMZ de Google Earth, a GeoJSON, de manera que estos datos puedan ser manipulados dentro de la plataforma para:
                    <ul class="body-1">
                        <li>Visualizar datos en los mapas.</li>
                        <li>Ser usados internamente para evaluación de areas.</li>
                    </ul>
                </v-label>
            </v-col>
            <v-col cols="12" md="6">
                <v-card outlined flat class="mx-2" v-if="!!item">
                    <v-simple-table dense>
                        <tbody>
                            <tr>
                                <td colspan="2" class="text-center font-weight-bold">Propiedades del archivo a ser convertido</td>
                            </tr>
                            <tr>
                                <td class="font-weight-bold">Nombre</td>
                                <td width="200">{{ item.nombre }}</td>
                            </tr>
                            <tr>
                                <td class="font-weight-bold">Subido en</td>
                                <td width="200">{{ item.fecha_creacion | moment("L HH:mm") }}</td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                </v-card>
            </v-col>
        </v-row>
        <v-alert dense :type="success ? 'success' : error ? 'error' : 'info'">
            <template v-if="success">¡El proceso ha finalizado de forma exitosa!</template>
            <template v-else-if="error">No fue posible realizar la conversión de datos para este KMZ.</template>
            <template v-else>Listo para comenzar el proceso</template>
        </v-alert>
        <v-alert v-if="success" dense :type="layers_count < 100 ? 'info' : 'warning'">
            <template v-if="layers_count < 100">Capas de datos convertidas: {{ layers_count }}</template>
            <template v-else>
                Advertencia: ha convertido un archivo muy grande. <br/>
                Si usted usa este archivo como una capa adicional, junto a otras capas igual de grandes en cantidad de datos, 
                el rendimiento de los mapas se verá fuertemente afectado.
            </template>
        </v-alert>
        <div class="text-center" v-if="!success || !error">
            <v-btn :disabled="busy" large depressed dark color="primary" class="subtitle-2" @click="apply">Iniciar conversión</v-btn>
        </div>
    </CustomDialog>
</template>

<script>
import { axiosHelper, messages } from '@/helpers'
import CustomDialog from '@/shared/components/CustomDialog'

export default {
    components: {
        CustomDialog
    },
    data: () => ({
        item: null,
        busy: false,
        success: false,
        error: false
    }),
    methods: {
        show(item) {
            this.$refs.dialog.persistent = true;
            this.layers_count = 0;
            this.success = false;
            this.error = false;

            this.item = Object.assign({}, item);

            this.$refs.dialog.show();
        },
        close() {
            if(this.success) {
                this.$emit('success');
            }
            this.item = null;
            this.$refs.dialog.hide();
        },
        apply() {
            if(!this.item) return;
            this.busy = true;
            this.$refs.dialog.actions_enabled = false;

            axiosHelper.post('kmz/' + this.item.id + '/convert/')
                .then(response => {
                    this.layers_count = response.data.layers;
                    this.$refs.dialog.actions_enabled = true;
                    this.busy = false;
                    this.success = true;
                })
                .catch(error => {
                    this.$refs.dialog.actions_enabled = true;
                    this.busy = false;
                    this.success = false;
                    this.error = true;
                });
        }
    }
}
</script>