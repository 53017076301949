<template>
    <div>
        <v-row v-if="!$parent.$parent.get_permission('show')">
            <v-col>
                <AccessMessage />
            </v-col>
        </v-row>
        <v-row v-else>
            <v-col cols="12">
                <MapCardV2 :satellite="true" ref="mapa" height="720" :interactive="true" />
            </v-col>
            <v-col cols="12">
                <DashCard title="Administrar" title-icon="settings" body-class="pa-0">
                    <v-tabs fixed-tabs>
                        <v-tab><span class="subtitle-1 font-weight-bold">Capas</span></v-tab>
                        <v-tab><span class="subtitle-1 font-weight-bold">Archivos KMZ</span></v-tab>
                        <v-tab-item>
                            <v-divider />
                            <v-row class="ma-0" align="center">
                                <v-col class="flex-grow-0 flex-shrink-1">
                                    <v-btn class="subtitle-2" depressed large color="primary" @click="$refs.editor_capa.show(false, null)">
                                        <v-icon left>add</v-icon>Crear nueva capa
                                    </v-btn>
                                </v-col>
                                <v-col class="flex-grow-1 flex-shrink-0">
                                    <v-text-field solo flat clearable dense outlined hide-details 
                                        v-model="search.capas" prepend-inner-icon="search" label="Buscar por nombre de capa o de archivo" />
                                </v-col>
                            </v-row>
                            <v-divider />
                            <v-data-table dense :search="search.capas" :headers="header_capamapa" :items="capas_mapas" :mobile-breakpoint="0">
                                <template v-slot:item.tipo="{ item }">
                                    <v-chip dark>{{ get_tipo(item.tipo) }}</v-chip>
                                </template>
                                <template v-slot:item.fuente.archivo="{ item }">
                                    <a :href="item.fuente.archivo">{{ item.fuente.archivo | url_filename }}</a>
                                </template>
                                <template v-slot:item.fecha_modificacion="{ item }">
                                    {{ item.fecha_modificacion | moment("L HH:mm:ss") }}
                                </template>
                                <template v-slot:item.fuente.convertido="{ item }">
                                    <template v-if="!item.fuente.convertido">
                                        <v-icon color="red" left>error</v-icon>Requiere conversión
                                    </template>
                                    <template v-else>
                                        <v-icon color="green" left>check_circle</v-icon>Convertido
                                    </template>
                                </template>
                                <template v-slot:item.actions="{ item }">
                                    <v-menu offset-y>
                                        <template v-slot:activator="{ on }">
                                            <v-btn text fab small color="primary" depressed v-on="on">
                                                <v-icon>more_vert</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-list :dense="$vuetify.breakpoint.mdAndUp">
                                            <v-list-item @click="$refs.editor_capa.show(true, item)">
                                                <v-list-item-icon>
                                                    <v-icon>edit</v-icon>
                                                </v-list-item-icon>
                                                <v-list-item-title>Editar</v-list-item-title>
                                            </v-list-item>
                                            <v-list-item @click="ask_delete_capa(item)">
                                                <v-list-item-icon>
                                                    <v-icon>close</v-icon>
                                                </v-list-item-icon>
                                                <v-list-item-title>Eliminar</v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </template>
                            </v-data-table>
                        </v-tab-item>
                        <v-tab-item>
                            <v-divider />
                            <v-row class="ma-0">
                                <v-col class="flex-grow-0 flex-shrink-1" align="center">
                                    <v-btn class="subtitle-2" depressed large color="primary" @click="$refs.uploader_kmz.show()">
                                        <v-icon left>cloud_upload</v-icon>Subir nuevo KMZ
                                    </v-btn>
                                </v-col>
                                <v-col class="flex-grow-1 flex-shrink-0">
                                    <v-text-field solo flat clearable dense outlined hide-details 
                                        v-model="search.kmz" prepend-inner-icon="search" label="Buscar por nombre de capa o de archivo" />
                                </v-col>
                            </v-row>
                            <v-divider />
                            <v-data-table dense :search="search.kmz" :headers="header_kmz" :items="kmzs" :mobile-breakpoint="0">
                                <template v-slot:item.archivo="{ item }">
                                    <v-btn text depressed :href="item.archivo" target="_blank" class="subtitle-2" color="green">
                                        <v-icon left>get_app</v-icon>Descargar
                                    </v-btn>
                                </template>
                                <template v-slot:item.convertido="{ item }">
                                    <template v-if="!item.convertido">
                                        <v-btn text depressed dark color="primary" @click="convert_kmz(item)" target="_blank" class="subtitle-2">
                                            <v-icon left>layers</v-icon>Convertir
                                        </v-btn>
                                    </template>
                                    <template v-else>
                                        <v-icon color="green" left>check_circle</v-icon>Convertido
                                    </template>
                                </template>
                                <template v-slot:item.fecha_creacion="{ item }">
                                    {{ item.fecha_creacion | moment("L HH:mm:ss") }}
                                </template>
                                <template v-slot:item.actions="{ item }">
                                    <v-menu offset-y>
                                        <template v-slot:activator="{ on }">
                                            <v-btn text fab small color="primary" depressed v-on="on">
                                                <v-icon>more_vert</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-list :dense="$vuetify.breakpoint.mdAndUp">
                                            <v-list-item :href="item.archivo" target="_blank">
                                                <v-list-item-icon>
                                                    <v-icon>get_app</v-icon>
                                                </v-list-item-icon>
                                                <v-list-item-title>Descargar</v-list-item-title>
                                            </v-list-item>
                                            <v-list-item @click="ask_delete_kmz(item)">
                                                <v-list-item-icon>
                                                    <v-icon>close</v-icon>
                                                </v-list-item-icon>
                                                <v-list-item-title>Eliminar</v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </template>
                            </v-data-table>
                        </v-tab-item>
                    </v-tabs>
                </DashCard>
            </v-col>
        </v-row>
        <CommonDialog ref="delete_capa_dialog" @onClose="delete_capa" />
        <CommonDialog ref="delete_kmz_dialog" @onClose="delete_kmz" />
        <Message ref="dialog" />
        <DialogEditarCapa ref="editor_capa" :kmzs="kmzs" @success="$parent.$parent.reloadComponent()" />
        <DialogSubirKMZ ref="uploader_kmz" @success="$parent.$parent.reloadComponent()" />
        <DialogConvertKMZ ref="convert_dialog" @success="$parent.$parent.reloadComponent()" />
    </div>
</template>

<script>
import { axiosHelper, messages } from '@/helpers'
import DashCard from '@/shared/components/DashCard'
import CommonDialog from '@/shared/components/CommonDialog'
import Message from '@/shared/components/Message'
import DialogEditarCapa from '@/dash/dialogs/DialogEditarCapa'
import DialogSubirKMZ from '@/dash/dialogs/DialogSubirKMZ'
import DialogConvertKMZ from '@/dash/dialogs/DialogConvertKMZ'
import MapCardV2 from '@/dash/components/MapCardV2'
import AccessMessage from '@/dash/components/AccessMessage'

export default {
    components: {
        DashCard,
        MapCardV2,
        CommonDialog,
        DialogConvertKMZ,
        DialogEditarCapa,
        DialogSubirKMZ,
        Message,
        AccessMessage
    },
    created() {
        this.$emit('title', { text: "Capas KMZ", icon: "map" });
    },
    methods: {
        convert_kmz(item) {
            this.$refs.convert_dialog.show(item);
        },
        get_tipo(tipo) {
            if(tipo == 1) return "Incidentes";
            if(tipo == 2) return "Restricciones";
            if(tipo == 3) return "SIAS";
            return "Otros";
        },
        ask_delete_capa(item) {
            const dialog = this.$refs.delete_capa_dialog;
            dialog.showDialog(messages.titles["question_eliminar_capa"], messages.texts["question_eliminar_capa"], "yes_no", item);
        },
        ask_delete_kmz(item) {
            const dialog = this.$refs.delete_kmz_dialog;
            dialog.showDialog(messages.titles["question_eliminar_kmz"], messages.texts["question_eliminar_kmz"], "yes_no", item);
        },
        delete_kmz(response, item) {
            if(response != 'yes') return;

            axiosHelper.del('kmz/' + item.id + '/')
                .then(response => {
                    this.load_table_data();
                })
                .catch(error => {
                    this.$refs.dialog.show(messages.titles["error_generico"], error.response.data.detail || messages.texts["error_desconocido"], "error");
                });
        },
        delete_capa(response, item) {
            if(response != 'yes') return;

            axiosHelper.del('capamapas/' + item.id + '/')
                .then(response => {
                    this.$parent.$parent.reloadComponent();
                })
                .catch(error => {
                    this.$refs.dialog.show(messages.titles["error_generico"], error.response.data.detail || messages.texts["error_desconocido"], "error");
                });
        },
        load_capas() {
            var mapa = this.$refs.mapa;
            axiosHelper.get('evaluador/').then(response => this.capas = response.data)
                .then(() => {
                    mapa.set_busy(true);
                    for(var c of this.capas) {
                        mapa.push_layer(c.name, c);
                    }
                    mapa.set_busy(true);
                    mapa.process_layers();
                })
                .then(() => {
                    mapa.refresh();
                    mapa.set_busy(false);
                });
        },
        load_table_data() {
            axiosHelper.get('kmz/').then(response => this.kmzs = response.data);
            axiosHelper.get('capamapas/').then(response => this.capas_mapas = response.data);
        }
    },
    data: () => ({
        capas_mapas: [],
        kmzs: [],
        search: {},
        header_capamapa: [
            { text: "Tipo de Capa", value: "tipo", sortable: false, width: '200px' },
            { text: "Nombre del KMZ", value: "fuente.nombre", sortable: false },
            { text: "Actualizado", value: "fecha_modificacion",  width: '200px', sortable: false },
            { text: "Compatibilidad", value: "fuente.convertido",  width: '200px', sortable: false },
            { text: "Acciones", value: "actions", sortable: false, align: 'right', width: '45px' }
        ],
        header_kmz: [
            { text: "Nombre registrado", value: "nombre" },
            { text: "Subido el", value: "fecha_creacion", align: 'right' },
            { text: "Compatibilidad", value: "convertido", align: 'center', width: '200px', sortable: false },
            { text: "", value: "actions", sortable: false, align: 'right', width: '45px' }
        ]
    }),
    mounted() {
        if(!this.$parent.$parent.get_permission('show')) return;

        this.$nextTick(this.load_capas);
        this.load_table_data();
    }
}
</script>