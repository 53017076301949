<template>
    <v-dialog v-model="dialog.visible" max-width="800" persistent transition="fade-transition">
        <WindowCard :title="dialog.title" actions-align="right">
            <v-form id="form" ref="creator_form">
                <v-row>
                    <v-col cols="12">
                        <v-label>Tipo de Capa</v-label>
                        <v-radio-group row hide-details v-model="creator.main_item.tipo" mandatory :rules="[creation_rules.required]">
                            <template v-for="(t, i) in tipos">
                                <v-radio :key="i" :value="t.value" :label="t.text" />
                            </template>
                        </v-radio-group>
                    </v-col>
                    <v-col cols="12">
                        <v-label>Archivo KMZ</v-label>
                        <v-autocomplete 
                            :rules="[creation_rules.required]"
                            solo flat hide-details dense outlined autocomplete="off" 
                            v-model="creator.main_item.fuente" 
                            :item-text="(i) => i.nombre +  ' - ' + $options.filters.url_filename(i.archivo)" 
                            item-value="id"
                            :items="kmzs" label="mapa.kmz" />
                    </v-col>
                </v-row>
            </v-form>
            <template slot="actions">
                <v-btn text large color="primary" @click="close()">
                    <span class="subtitle-2">Descartar</span>
                </v-btn>
                <v-btn large depressed color="primary" type="submit" form="creator_form" @click="upload">
                    <v-icon>done</v-icon>
                    <span class="ml-2 subtitle-2">{{ (creator.edit ? 'Guardar' : 'Crear') }}</span>
                </v-btn>
            </template>
        </WindowCard>
        <Message ref="dialog" />
    </v-dialog>
</template>

<script>
import WindowCard from '@/shared/components/WindowCard'
import Message from '@/shared/components/Message'
import { axiosHelper, messages, valid_required } from '@/helpers'

export default {
    props: ['kmzs'],
    components: {
        WindowCard,
        Message
    },
    data: () => ({
        dialog: {
            visible: false,
            title: ''
        },
        creator: {
            main_item: {},
            edit: false
        },
        creation_rules: {
            required: value => valid_required(value)
        },
        tipos: [
            { text: "Incidentes",       value: 1 },
            { text: "Restricciones",    value: 2 },
            { text: "Sias",             value: 3 },
            { text: "Otros",            value: 4 }
        ]
    }),
    methods: {
        show(edit, main_item) {
            if(this.$refs.creator_form)
                this.$refs.creator_form.reset();

            this.dialog.visible = true;
            this.creator.edit = edit;

            if(this.creator.edit) {
                this.dialog.title = "Editar Capa de Mapa";
                this.creator.main_item = {... main_item};
            } else {
                this.dialog.title = "Crear Capa de Mapa";
            }
        },
        close() {
            this.dialog.visible = false;
            this.creator.main_item = {};

            if(this.$refs.creator_form)
                this.$refs.creator_form.reset();
        },
        error(data) {
            let text = messages.texts["error_check_fields"];

            if(data) {
                text = data;
            }
            this.$refs.dialog.show(messages.titles["error_generico"], text, "error");
        },
        upload(e) 
        {
            e.preventDefault();

            if(!this.$refs.creator_form.validate()) {
                this.error();
                return;
            }

            if(!!this.creator.main_item.fuente.id) {
                this.creator.main_item.fuente = this.creator.main_item.fuente.id;
            }
            
            if(this.creator.edit) {

                axiosHelper.put('capamapas/' + this.creator.main_item.id + '/', this.creator.main_item)
                    .then(response => {
                        this.$emit('success');
                    })
                    .catch(error => this.error(error));
            } else {
                axiosHelper.post('capamapas/', this.creator.main_item)
                    .then(response => {
                        this.$emit('success');
                    })
                    .catch(error => this.error(error));
            }
        }
    }
}
</script>