<template>
    <v-dialog v-model="dialog.visible" max-width="800" persistent transition="fade-transition">
        <WindowCard :title="dialog.title" actions-align="right">
            <v-form id="form" ref="creator_form">
                <v-row>
                    <v-col cols="12">
                        <v-label>Nombre del archivo</v-label>
                        <v-text-field hide-details solo flat dense outlined v-model="creator.main.nombre" label="Nombre del archivo" :rules="[creation_rules.required]" />
                    </v-col>
                    <v-col cols="12" v-if="!dialog.uploading">
                        <v-label>Archivo</v-label>
                        <v-file-input hide-details show-size solo flat dense outlined @change="update_file" label="archivo.kmz" truncate-length="50" accept=".kmz" :rules="[creation_rules.required]" />
                    </v-col>
                    <v-col cols="12" v-else>
                        <v-progress-linear :value="dialog.progress" striped rounded height="32" color="secondary">
                            <template v-slot="{ value }">
                                <strong>{{ Math.ceil(value) }}%</strong>
                            </template>
                        </v-progress-linear>
                    </v-col>
                </v-row>
            </v-form>
            <template slot="actions">
                <v-btn text large color="primary" @click="close()" v-show="!dialog.uploading">
                    <span class="subtitle-2">Descartar</span>
                </v-btn>
                <v-btn large depressed color="primary" type="submit" form="creator_form" @click="upload" :loading="dialog.uploading">
                    <v-icon>cloud_upload</v-icon>
                    <span class="ml-2 subtitle-2">Subir archivo</span>
                </v-btn>
            </template>
        </WindowCard>

        <Message ref="error" />
        <Message ref="dialog" @onClose="$emit('success')" />
    </v-dialog>
</template>

<script>
import WindowCard from '@/shared/components/WindowCard'
import Message from '@/shared/components/Message'
import { axiosHelper, messages, valid_required } from '@/helpers'

export default {
    props: ['empleados'],
    components: {
        WindowCard,
        Message
    },
    data: () => ({
        dialog: {
            visible: false,
            title: '',
            progress: 0,
            uploading: false,
        },
        creator: {
            main: {},
            edit: false
        },
        creation_rules: {
            required: value => valid_required(value)
        }
    }),
    methods: {
        show() {
            if(this.$refs.creator_form)
                this.$refs.creator_form.reset();

            this.dialog.visible = true;
            this.dialog.title = "Subir nuevo archivo KMZ";
        },
        close() {
            this.dialog.visible = false;
            this.creator.main = {};

            if(this.$refs.creator_form)
                this.$refs.creator_form.reset();
        },
        error(data) {
            let text = messages.texts["error_check_fields"];

            if(data) {
                text = data;
            }
            this.$refs.error.show(messages.titles["error_generico"], text, "error");
        },
        update_file(file) {
            this.creator.main.archivo = file;
        },
        upload() 
        {
            if(!this.$refs.creator_form.validate()) 
            {
                this.error();
                return;
            }
            this.dialog.uploading = true;
            let form_data = new FormData();

            form_data.append('nombre', this.creator.main.nombre);
            form_data.append('archivo', this.creator.main.archivo);

            let options = { onUploadProgress: progressEvent => this.dialog.progress = (progressEvent.loaded / progressEvent.total) * 100 };

            axiosHelper.post('kmz/', form_data, { 'content-type': 'multipart/form-data' }, options)
                .then(response => {
                    this.dialog.uploading = false;
                    this.$refs.dialog.show(messages.titles["success_generico"], "El archivo KMZ se ha subido con éxito.", "success");
                })
                .catch(error => this.error(error));
        }
    }
}
</script>